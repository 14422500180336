@import "../../../const/variables.scss";


.contactContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4rem;
    margin-top: 12rem;
    padding-bottom: 4rem;

    #subtitle {
        font-size: 2.5rem;
        font-family: $montserrat;
        color: $purple;
    }

    form {
        display: flex;
        align-items: baseline;
        justify-content: center;
        flex-direction: column;
        gap: 2rem;
        transition: opacity 500ms;
        position: relative;

        &>*:not(#birdie) {
            background: none;
            border: 2px solid white;
            border-radius: 10px;
            min-height: 20px;
            font-weight: bold;
            padding: 5px;
            color: white;
            font-family: $montserrat;
        }

        input,
        textarea:focus,
        input,
        textarea:active {
            outline: none;
        }

        textarea {
            height: 20vh;
            width: 50vw;
            resize: none;
            z-index: 5;

            @media only screen and (max-width: 1100px) {
                width: 80vw;
            }
        }

        button {
            padding: 5px 25px;
            transition: 300ms;
            cursor: pointer;
        }

        #birdie {

            @media only screen and (max-width: 1100px) {
                display: none;
            }

            position: absolute;
            right: 20px;
            top: 0;
            transform: translateY(25px);
            z-index: 3;
        }
    }
}