@import "../../const/variables.scss";

.aboutPage {
    padding-top: 100px;
    z-index: 3;


    #movingBox {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 70vh;
        position: fixed;
        z-index: 2;

        &>div {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            position: absolute;
        }

        #hiThereContainer {
            transition: 500ms;
            opacity: 0;
            pointer-events: none;

            @media only screen and (max-width: 1100px) {
                flex-direction: column;
            }


            &.visible {
                opacity: 1;
                pointer-events: initial;
            }

            img {
                height: 300px;
                margin: 100px 0;
                transition: 800ms;
            }

            #hiThere {
                align-self: baseline;
                transition: opacity 800ms;

                @media only screen and (max-width: 1100px) {
                    width: 100%;
                    justify-content: center;
                }

                display: flex;
                align-items: end;
                justify-self: end;
                gap: 110px;

                #lilHand {
                    width: 50px;
                    transform-origin: bottom;
                    transform: rotateZ(-20deg);
                    animation: 1s lilHandAnim linear infinite;
                }
            }
        }

        #imMihaiContainer {
            align-self: baseline;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            opacity: 0;
            transition: 500ms;
            z-index: 3;


            opacity: 0;
            pointer-events: none;

            &.visible {
                opacity: 1;
                pointer-events: initial;
            }

            #title {
                font-family: $montserrat;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }

            #slidesContainer {
                width: 100%;
                height: 200px;
                position: relative;
                z-index: 3;

                .slide {
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(270deg, #7338D3 0%, rgba(13, 28, 42, 0) 100%);
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    position: absolute;

                    transition: 500ms;

                    opacity: 0;
                    pointer-events: none;

                    &.visible {
                        opacity: 1;
                        pointer-events: initial;
                    }

                    @media only screen and (max-width: 1100px) {
                    
                        svg{
                            transform: scale(.6) translateY(-80%);
                            right: unset !important;
                            left: unset !important;
                        }
                    }

                    svg {
                        position: absolute;
                        right: 30px;
                        height: 340px;
                        width: max-content;
                    }

                    h2 {
                        transform: translateX(-15vw);
                        @media only screen and (max-width: 1100px) {
                            transform: translateX(0);

                        }
                        font-family: $montserrat;
                        font-weight: 100;
                    }

                    &.left {
                        background: linear-gradient(90deg, #7338D3 0%, rgba(13, 28, 42, 0) 100%) !important;
                        // transform: translateX(-100%);

                        svg {
                            left: 30px;
                        }

                        h2 {
                            transform: translateX(15vw);

                        }
                    }

                }
            }
        }

        #overallContainer {
            transition: 500ms;
            opacity: 0;
            pointer-events: none;

            &.visible {
                opacity: 1;
                pointer-events: initial;
            }

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 7vh;

            font-family: $montserrat;

            h2 {
                font-weight: 400;
            }

            p {
                font-weight: 100;
                font-size: 1rem;
                max-width: 65vw;
                text-align: center;
            }

            svg {
                width: 40vw;
                @media only screen and (max-width: 1100px) {
                    width: 75vw;

                }

                #Vector_4,
                #Vector_5,
                #hooman-hammock {
                    animation: 5s hammock linear infinite;
                    transform: rotateX(15deg) skewX(1deg);

                }
            }
        }
    }
}

@keyframes hammock {

    0%,
    100% {
        transform: rotateX(15deg) skewX(1deg);
    }

    50% {
        transform: rotateX(-15deg) skewX(1deg);

    }
}

.hiThereOff {
    #hiThere {
        transform: translateX(-30px);
        opacity: 0;
    }

    img {
        transform: translateX(30px);
        opacity: 0;
    }
}


@keyframes lilHandAnim {

    0%,
    100% {
        transform: rotateZ(-10deg);
    }

    50% {
        transform: rotateZ(10deg);

    }
}

#iconOverlayContainer {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 0;

    div {
        position: absolute;
    }

    svg {
        width: 30px;
    }

    #icon-1 {
        top: 10vh;
        left: 19vw;
    }

    #icon-2 {
        top: 20vh;
        left: 2vw;
    }

    #icon-3 {
        top: 30vh;
        left: 15vw;
    }

    #icon-4 {
        top: 40vh;
        left: 5vw;
    }

    #icon-5 {
        top: 50vh;
        left: 17vw;
    }

    #icon-6 {
        top: 60vh;
        left: 27vw;
    }

    #icon-7 {
        top: 70vh;
        left: 13vw;
    }

    #icon-8 {
        top: 80vh;
        left: 6vw;
    }

    #icon-9 {
        top: 15vh;
        right: 19vw;
    }

    #icon-10 {
        top: 30vh;
        right: 10vw;
    }

    #icon-11 {
        top: 45vh;
        right: 27vw;
    }

    #icon-12 {
        top: 60vh;
        right: 17vw;
    }

    #icon-0 {
        top: 80vh;
        right: 10vw;
    }
}