@import '../../../const/variables.scss';

#socialMediaContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    button {

        border: none;
        background: transparent;
        cursor: pointer;
        transition: 300ms;
        width: 60px;
        height: 60px;
        
        svg{
            width: 100%;
        }

        &:focus,
        &:active {
            outline: none;
        }
        &:hover{
            transform: scale(.9);
            opacity: .5;
        }
    }
}