@import "../../const/variables.scss";

.papersPage {
  width: 100%;
  #papersLanding {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-width: 100%;

    @media only screen and (max-width: 1100px) {
      flex-wrap: wrap;

      & > * {
        min-width: 250px;
      }
    }

    width: 100%;

    position: fixed;
    top: 5vh;

    opacity: 0;
    z-index: -1;
    transition: 300ms;

    &.visible {
      opacity: 1;
      z-index: 1;

      svg,
      & > div {
        z-index: 1;
        transform: translateX(0px);
        opacity: 1;
      }
    }

    svg {
      z-index: -1;
      transition: 300ms;
      transform: translateX(-50px);
      width: 30vw;
      opacity: 0;

      @media only screen and (max-width: 1100px) {
        order: 2;
      }
    }

    & > div {
      @media only screen and (max-width: 1100px) {
        order: 1;
      }

      z-index: -1;
      opacity: 0;
      transition: 300ms;
      transform: translateX(50px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      max-width: 35vw;

      font-family: $montserrat;
    }
  }

  #papersContainer {
    padding-top: 60vh;
    display: flex;
    align-items: start;
    margin-left: 50%;
    transform: translate(-50%);
    width: max-content;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20vh;
    gap: 50px;
    max-width: 100%;

    .paper {
      width: max-content;
      display: flex;
      align-items: start;
      justify-content: center;
      flex-direction: column;
      position: relative;

      @media only screen and (max-width: 1100px) {
        width: 100% !important;
        a {
          color: grey !important;
        }
      }

      span:nth-of-type(1) {
        font-weight: bold;
        font-style: italic;
      }
      a {
        max-width: 100%;
        color: white;
        text-decoration: none;
        &:hover {
          color: $purple;
        }
      }

      &::before {
        content: "";
        position: absolute;
        height: 120%;
        width: 1px;
        background: white;
        transform: translateX(-10px);
      }
    }
  }
}
