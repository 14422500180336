@import "./const/variables.scss";

.App{
    background: $darkblue;
    margin: 0;
    padding: 0;
    width: 100vw;
    min-height: 100vh;
    
    // padding-bottom: 30cm;
    #four04{
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        svg{
            width: 50vw;
        }
    }
}