@import "../../../const/variables.scss";

.topSkillsContainer {
    width: 100%;
    height: 94vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $purple;

    gap: 2rem;

    @media only screen and (max-width: 1100px) {
        justify-content: start;
        padding-top: 10vh;
        gap: 0;
    }



    flex-direction: column;

    &>h3 {
        font-family: $montserrat;
        font-weight: 400;
        text-align: center;

        @media only screen and (max-width: 1100px) {
            letter-spacing: initial !important;
        }
    }

    #jsContainer {

        position: relative;

        width: max-content;

        height: 300px;
        width: 220px;
        top: 35vh;
        position: sticky;
        text-align: center;

        #frontendBox,
        #backendBox {
            height: inherit;
            width: inherit;
            position: absolute;
            padding: 0px 10px;
            border-radius: 7.5px;

            &,
            &>* {
                transition: 800ms;
                transition-timing-function: ease-in-out;
            }

            h2 {
                font-family: $montserrat;
                font-weight: 400;
                opacity: 1;
            }

            span {
                font-family: $montserrat;
                font-weight: 600;
            }

            .jsContainerTech {
                padding: 30px 0;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                h3 {
                    font-family: $montserrat;
                    font-weight: 400;
                    margin: 0;
                }
            }

            .jsContainerImg {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                height: 70px;

                #react-logo {
                    width: 70px;
                }

                #firebase-logo {
                    width: 120px;
                }

                #nodejs-logo {
                    width: 70px;
                }
            }
        }

        #frontendBox {
            color: white;
            background: $purple;
            z-index: 5;
        }

        #backendBox {
            color: $purple;
            background: white;
            transform: translateX(.8%) translateY(1%);
            z-index: 4;
        }
    }
}



.hiddenCard {
    & {
        background: transparent !important;
    }

    &>* {
        opacity: 0;
    }
}

.feCardTransition {
    transform: translateX(-150%);

    @media only screen and (max-width: 1100px) {
        transform: translateX(0);
    }
}

.beCardTransition {
    transform: translateX(150%) !important;

    @media only screen and (max-width: 1100px) {
        transform: translateX(0%) translateY(120%) !important;
    }
}


#proudAnimContainer {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}