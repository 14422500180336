@import '../../../const/variables.scss';

.menuContainer {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: rgba(115, 56, 211, .97);
    z-index: 75;



    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    padding-top: 150px;

    transition: opacity 200ms;

    a:not(a:first-of-type) {
        padding: 30px 0;
    }

    a:not(:last-child){
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: 50vw;
            height: 1px;
            background: white;

            @media only screen and (max-width: 1100px) {
                width: 80vw;
            }
        }
    }

    a:first-of-type {
        padding: 0 0 30px 0;

    }

    a {
        position: relative;
        cursor: pointer;
        background: none;
        border: none;
        width: 50vw;
        font-size: 1.5em;
        text-align: center;
        text-decoration: none;

        @media only screen and (max-width: 1100px) {
            white-space: nowrap;
            width: 80vw;
            font-size: 1.2rem;
        }

        transition: 300ms;
        color: white;

        &:focus,
        &:active {
            outline: none;
            color: white;
            letter-spacing: 3px;
        }

        &:hover {
            letter-spacing: 3px;
        }


    }
}


.menuButton {
    position: fixed;
    top: 15px;
    right: 15px;

    background: $purple;
    padding: 7px 5px 4px 5px;
    border-radius: 10px;

    cursor: pointer;

    z-index: 80;

    & * {
        transition: 200ms;
    }

    #topline {
        transform-origin: top;
    }

    #bottomline {
        transform-origin: bottom;
    }
}

.intermediaryState {
    #topline {
        transform: translateY(8.7px);
    }

    #bottomline {
        transform: translateY(-8.7px);
    }

    #midline {
        opacity: 0;
    }
}

.openedState {
    #topline {
        transform: translateY(9px) rotateZ(45deg) scale(.9);
    }

    #bottomline {
        transform: translateY(-10px) rotateZ(-45deg) scale(.9);
    }

    #midline {
        opacity: 0;
    }
}