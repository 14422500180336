@import "../../../const/variables.scss";

.landingContainer {
    display: flex;
    align-items: center;
    justify-content: baseline;
    min-height: 100vh;

    @media only screen and (max-width: 1100px) {
        min-height: max-content;
        flex-direction: column;
        gap: 0;

    }


    #landingArrow {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        transition: transform 300ms;
        cursor: pointer;
    }

    #landingArrow:hover {
        transform: translateX(-50%) translateY(20%);
    }

    #randomCircle {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        height: 1cm;
        width: 1cm;
        background: white;
        border-radius: 100%;
    }
}

.landingLeftContainer {
    margin-left: 3em;
    margin-top: 4em;

    height: 70vh;
    width: 55vw;
    padding-top: 7rem;

    @media only screen and (max-width: 1100px) {

        padding-top: 0rem;
        height: 200px;
        margin-top: 0;
    }

    display: flex;
    align-items: baseline;
    flex-direction: column;

    justify-content: space-between;

    h1 {
        font-family: $alfa;
        letter-spacing: 1px;
        font-weight: 100;
        font-size: 2.6rem;
    }

    .landingDemoText {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4rem;
        font-family: $montserrat;
        flex-wrap: wrap;

        @media only screen and (max-width: 1100px) {
            display: none;
        }


        color: $purple;

        p {
            width: 15rem;
        }
    }
}

.landingRightContainer {
    background: $purple;

    height: 100%;
    width: 500px;

    position: absolute;
    right: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media only screen and (max-width: 1100px) {
        position: relative;
        background: transparent;
        height: max-content;
        padding: 0;
        width: 100vw;
    }

}

.landingSvgContainer {
    transform: translateX(-50%);

    @media only screen and (max-width: 1100px) {
        position: relative;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        transform: translateX(0%);
        width: 100%;

        svg {
            position: relative;
            transform: scale(1.5);
        }

    }

    &>* {
        max-width: 50vw;
        max-height: 80vw;
    }

    #pic_container {
        background-image: url("../../../assets/me.png");
        background-position: center;
        background-size: contain;
    }
}


.bikeContainer {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    right: 0;
    width: inherit;

    @media only screen and (max-width: 1100px) {
        display: none;
    }

    #bike {
        animation: 10s bike_move_anim linear infinite;
    }
}


@keyframes bike_move_anim {
    from {
        transform: translateX(1400%);
    }

    to {
        transform: translateX(-100%);
    }
}