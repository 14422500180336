@import "../../const/variables.scss";

.projectsPage {

    #projectsLanding {
        display: flex;
        align-items: center;
        justify-content: space-around;

        @media only screen and (max-width: 1100px) {
            flex-wrap: wrap;

            &>* {
                min-width: 300px;
            }
        }

        width: 100%;


        position: fixed;
        top: 10vh;


        opacity: 0;
        z-index: -1;
        transition: 300ms;


        &.visible {

            opacity: 1;
            z-index: 1;

            svg,
            &>div {
                z-index: 1;
                transform: translateX(0px);
                opacity: 1;

            }
        }

        svg {
            z-index: -1;
            transition: 300ms;
            transform: translateX(-50px);
            width: 30vw;
            opacity: 0;

            @media only screen and (max-width: 1100px) {
                order: 2;
            }
        }

        &>div {
            @media only screen and (max-width: 1100px) {
                order: 1;
            }

            z-index: -1;
            opacity: 0;
            transition: 300ms;
            transform: translateX(50px);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            max-width: 35vw;

            font-family: $montserrat;
        }
    }

    #projectsContainer {
        padding-top: 70vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 20vh;
        gap: 20vh;

        z-index: -1;
        opacity: 0;
        transition: 600ms;

        outline: 1px solid red;

        &.visible {
            z-index: 1;
            opacity: 1;
        }

        .project {
            width: 50vw;

            @media only screen and (max-width: 1100px) {
                width: 100%;
                padding: 0;
            }

            height: max-content;
            border-radius: 10px;
            background: linear-gradient(180deg, #7338D3 0%, rgba(0, 0, 0, 0) 100%);
            padding: 20px;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 30px;

            a,
            h3,
            p {
                font-family: $montserrat;
            }

            &>.projectTitle,
            &>.projectImages {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                &>* {
                    margin: 0;
                }
            }

            &>.projectTitle {
                a {
                    color: white;
                    text-decoration: none;
                    transition: 300ms;

                    &:hover {
                        letter-spacing: 1px;
                        color: $darkblue;
                    }
                }
            }

            &>.projectImages {
                gap: 10px;

                &>img {
                    border-radius: 5px;
                    max-width: 80%;
                }

                div {
                    @media only screen and (max-width: 1100px) {
                        display: none;
                    }

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;

                    img {
                        border-radius: 5px;
                        height: 100px;
                    }
                }
            }


            &>.projectRoles {
                display: flex;
                align-items: baseline;
                justify-content: space-around;
                text-align: center;
                width: 100%;

                h3 {
                    margin-bottom: 10px;
                }

                & * {
                    margin: 0;
                }
            }

            &>p {
                padding: 0 20px;
            }

        }

    }
}